import React, { useState } from 'react';
import steps from '../../data/steps';

const Stepper = () => {
  const video_url = 'https://www.youtube.com/embed/NqFb05A2MAs';

  const [currentStep, setCurrentStep] = useState(0);
  const [visibleStepContent, setVisibleStepContent] = useState(0);

  const handleStepClick = (index) => {
    if (window.innerWidth < 768) {
      setVisibleStepContent(visibleStepContent === index ? null : index);
    } else {
      setCurrentStep(index);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center gap-12 w-full'>
      {/* Desktop/Tablet Version */}
      <div className='hidden md:flex flex-col justify-center items-center w-full gap-12'>
        <ul className='flex items-center w-full max-w-6xl'>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <li
                onClick={() => handleStepClick(index)}
                className={`cursor-pointer ${
                  index === currentStep
                    ? 'text-primary-blue'
                    : 'text-primary-red'
                }`}
              >
                <div className='flex flex-col items-center'>
                  <div
                    className={`flex items-center justify-center text-2xl ${
                      index <= currentStep
                        ? 'text-primary-blue'
                        : 'text-gray-300'
                    } `}
                    title={step.label}
                  >
                    {step.icon}
                  </div>
                  <span className='mt-6 text-2xl font-medium text-primary-blue'>
                    {step.label}
                  </span>
                </div>
              </li>
              {index < steps.length - 1 && (
                <li className='flex-auto' key={index}>
                  <div
                    className={`h-0.5 ${
                      index < currentStep ? 'bg-primary-red' : 'bg-gray-300'
                    }`}
                  ></div>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
        <div className='hidden md:flex items-center justify-center text-center'>
          <p className='max-w-4xl min-h-20 text-xl font-light text-primary-blue'>
            {steps[currentStep]?.content}
          </p>
        </div>
        <div className='hidden md:flex w-full max-w-4xl'>
          <iframe
            src={video_url}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            className='w-full aspect-video rounded shadow-lg'
          ></iframe>
        </div>
      </div>
      {/* Mobile Version */}
      <div className='flex md:hidden flex-col justify-center items-center w-full gap-6'>
        <div className='w-full'>
          <iframe
            title='YouTube video player'
            src={video_url}
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
            className='w-full aspect-video rounded shadow-lg'
          ></iframe>
        </div>
        {steps.map((step, index) => (
          <div key={index} className='w-full'>
            <button
              onClick={() => handleStepClick(index)}
              className={`flex flex-col items-center justify-center px-4 py-3 w-full cursor-pointer ${
                visibleStepContent === index
                  ? 'text-primary-blue border-b-2 border-primary-red'
                  : 'text-primary-blue border-b-2 border-gray-300'
              }`}
            >
              <span className='text-xl font-medium'>{step.label}</span>
            </button>
            {visibleStepContent === index && (
              <div className='mt-6 text-justify'>
                <p className='text-lg font-light text-primary-blue'>
                  {step.content}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;