import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';
import Title from '../Title';

const ProdSupport = () => {
  return (
    <section className='flex flex-col p-6 md:p-12 gap-6 md:gap-12'>
      <Title>Interested?</Title>
      <div className='flex flex-col items-center justify-center text-center gap-6'>
        <p className='text-lg lg:text-xl font-light text-primary-blue'>
        To explore product ideas or process optimisation:
        </p>
        <Link to='/contact'>
          <Button>Get in Touch</Button>
        </Link>
      </div>
    </section>
  );
}

export default ProdSupport;