const cookielist = [
  { id: 'cookieyes-consent', domain: 'quantessence.com', duration: '1 year', description: 'CookieYes sets this cookie to remember users consent preferences so that their preferences are respected on subsequent visits to this site. It does not collect or store any personal information about the site visitors.' },
  { id: 'JSESSIONID', domain: 'linkedin.com', duration: 'end of browser session', description: 'New Relic uses this cookie to store a session identifier so that New Relic can monitor session counts for an application.' },
  { id: '__cf_bm', domain: 'vimeo.com', duration: '30 minutes', description: 'This cookie, set by Cloudflare, is used to support Cloudflare Bot Management.' },
  { id: 'lang', domain: 'linkedin.com', duration: 'end of browser session', description: 'LinkedIn sets this cookie to remember a user\'s language setting.' },
  { id: 'li_gc', domain: 'linkedin.com', duration: '5 months 27 days', description: 'LinkedIn sets this cookie for storing visitor\'s consent regarding using cookies for non-essential purposes.' },
  { id: 'lidc', domain: 'linkedin.com', duration: '1 day', description: 'LinkedIn sets the lidc cookie to facilitate data center selection.' },
  { id: 'player', domain: 'vimeo.com', duration: '1 year', description: 'Vimeo uses this cookie to save the user\'s preferences when playing embedded videos from Vimeo.' },
  { id: 'aka_debug', domain: 'akamaized.net', duration: 'end of browser session', description: 'Vimeo sets this cookie which is essential for the website to play video functionality.' },
  { id: 'CONSENT', domain: 'youtube.com', duration: '2 years', description: 'YouTube sets this cookie via embedded YouTube videos and registers anonymous statistical data.' },
  { id: 'vuid', domain: 'vimeo.com', duration: '1 year 1 month 4 days', description: 'Vimeo installs this cookie to collect tracking information by setting a unique ID to embed videos on the website.' },
  { id: '_tccl_visit', domain: 'quantessence.com', duration: '30 minutes', description: 'Third party cookie' },
  { id: '_tccl_visitor', domain: 'youtube.com', duration: '1 year', description: 'Third party cookie' },
  { id: 'sync_active', domain: 'quantessence.com', duration: 'never', description: 'This cookie is set by Vimeo and contains data on the visitor\'s video-content preferences, so that the website remembers parameters such as preferred volume or video quality.' },
  { id: 'YSC', domain: 'youtube.com', duration: 'end of browser session', description: 'Youtube sets this cookie to track the views of embedded videos on Youtube pages.' },
  { id: 'VISITOR_INFO1_LIVE', domain: 'youtube.com', duration: '5 months 27 days', description: 'YouTube sets this cookie to measure bandwidth, determining whether the user gets the new or old player interface.' },
  { id: 'yt-remote-device-id', domain: 'youtube.com', duration: 'never', description: 'YouTube sets this cookie to store the user\'s video preferences using embedded YouTube videos.' },
  { id: 'yt.innertube::requests', domain: 'youtube.com', duration: 'never', description: 'YouTube sets this cookie to register a unique ID to store data on what videos from YouTube the user has seen.' },
  { id: 'yt-remote-connected-devices', domain: 'youtube.com', duration: 'never', description: 'YouTube sets this cookie to store the user\'s video preferences using embedded YouTube videos.' },
  { id: 'yt.innertube::nextId', domain: 'youtube.com', duration: 'never', description: 'YouTube sets this cookie to register a unique ID to store data on what videos from YouTube the user has seen.' },
  { id: 'bcookie', domain: 'linkedin.com', duration: '1 year', description: 'LinkedIn sets this cookie from LinkedIn share buttons and ad tags to recognize browser IDs.' },
  { id: 'bscookie', domain: 'linkedin.com', duration: '1 year', description: 'LinkedIn sets this cookie to store performed actions on the website.' },
  { id: 'VISITOR_PRIVACY_METADATA', domain: 'youtube.com', duration: '5 months 27 days', description: 'N/A' },
  { id: 'li_alerts', domain: 'linkedin.com', duration: '1 year', description: 'N/A' },
  { id: '_lft_', domain: 'quantessence.com', duration: '1 year', description: 'This cookie helps Leadfeeder track individual visitors across multiple sessions, allowing them to attribute sessions to specific companies for lead generation purposes.' },
  { id: '_lfr_', domain: 'quantessence.com', duration: '1 year', description: 'This cookie tracks the referral source (e.g., where users came from before visiting the website). This information helps Leadfeeder attribute traffic sources to companies.' },
  { id: '_lfguid_', domain: 'quantessence.com', duration: '1 year', description: 'Used by Leadfeeder to identify unique visitors and assign them a specific company for lead tracking purposes. This cookie is persistent and does not collect personal information.' },
  { id: '_lftrack_', domain: 'quantessence.com', duration: '1 year', description: 'Leadfeeder uses this cookie to track user behavior across different pages on the website. This cookie is used to monitor visitor engagement and helps identify potential leads.' },
  { id: '_lfref_', domain: 'quantessence.com', duration: 'Session', description: 'This cookie tracks the referrer URL from which a user arrived on the website. It helps Leadfeeder track the source of traffic and understand user behavior.' },
  { id: '_lfoptout_', domain: 'quantessence.com', duration: '1 year', description: 'This cookie is used by Leadfeeder to remember if a user has opted out of tracking. It prevents the service from tracking the user in future sessions.' }
];

export default cookielist;
