import Portfolio from '../assets/products/unit-linked-support.jpg';
import Income from '../assets/products/growing-income.jpg';
import Fund from '../assets/products/smoothed-fund.jpg';
import Universal from '../assets/products/indexed-universal-life.jpg';
import Savings from '../assets/products/protected-savings-plan.jpg';
import Pension from '../assets/products/lifecycle-pension-plan.jpg';
import analysis from '../assets/products/cards/analysis.jpg';
import infra from '../assets/products/cards/infra.jpg';
import connections from '../assets/products/cards/connections.jpg';
import wiring from '../assets/products/cards/wiring.jpg';
import secure from '../assets/products/cards/secure.jpg';
import aichip from '../assets/products/cards/aichip.jpg';
import invest from '../assets/products/cards/invest.jpg';
import individual from '../assets/products/cards/individualinvest.jpg';
import automated from '../assets/products/cards/automation.jpg';
import monitor from '../assets/products/cards/datatick.jpg';
import freedom from '../assets/products/cards/freedom.jpg';


import {
  FaChartLine,
  FaCog,
  FaLocationArrow,
  FaLock,
  FaShieldAlt,
} from 'react-icons/fa';

const products = [
  {
    slug: 'protected-savings-plan',
    title: 'Protected Savings Plan',
    imagedisplay: Savings,
    description: `A secure and flexible solution for building retirement savings. Customers can make regular or one-off contributions and choose personalised investment options that align with their profile, balancing growth potential and capital protection—perfect for those seeking both security and returns.`,
    features: [
      {
        icon: FaChartLine,
        title: 'Protection Mechanism',
        description:
          'The protection mechanism is fully customisable, offering more flexibility than traditional approaches. It can be tailored based on age, profile, or individual customer choices, ensuring a unique fit for every customer.',
      },
      {
        icon: FaCog,
        title: 'Protection Options',
        description:
          'The plan can protect either customer’s contributions or the policy’s total value. In some cases, customers can switch the protection ON or OFF at any time, adapting to changing financial needs.',
      },
      {
        icon: FaShieldAlt,
        title: 'Maximum Flexibility',
        description:
          'Customers can adjust contribution plans, switch investment portfolios, or modify their risk profile as their financial circumstances evolve. They can also add or withdraw funds, pause or increase contributions, or change the plan’s maturity. In some cases, the savings plan seamlessly converts into a market-linked income plan at retirement, ensuring continued growth and ensuring customer retention.',
      },
    ],
    videoUrl: 'https://www.youtube.com/embed/_H7C60bZN8g',
    roles: [
      {
        title: 'Algorithm Design and Management',
        description: `At the core of the Protected Savings Plan are a fully customisable algorithms, designed to reflect the exact financial product you want to offer. We help shape their design, then handle the programming, testing, and flawless execution—so you can focus on your business and your clients.`,
        subs: [
          {
            title: 'Individual Investment Decisions',
            description:
              'Algorithms operate at the individual account or policy level, treating profile choices, contributions, withdrawals, and redemptions independently. This approach ensures maximum flexibility for the product.',
            image: invest,
          },
          {
            title: 'Protection Mechanism',
            description:
              'Algorithms dynamically adjust asset allocation —shifting to safer assets in downturns and capturing growth in strong markets.',
            image: secure,
          },
        ],
      },
      {
        title: 'Integration with Counterparties',
        description: `Our solution provides a single, centralised connection to the market. If you need to add or switch providers, no additional deployment is required on your side. We ensure smooth operations through:`,
        subs: [
          {
            title: 'Seamless Connections',
            description:
              'Secure integration with hedge providers, asset managers, and market data sources.',
            image: connections,
          },
          {
            title: 'Automated Operations',
            description:
              'Automated processing and trade execution minimises errors and streamlines processes.',
            image: infra,
          },
        ],
      },
      {
        title: 'Data Management and Storage',
        description: `We make a multitude of data easy to access, manipulate, and analyse. Our solution ensures:`,
        subs: [
          {
            title: 'Secure Data Storage',
            description:
              'Robust storage that safeguards sensitive financial data and meets all regulatory requirements.',
            image: aichip,
          },
          {
            title: 'Reconciliations and Rewinds',
            description:
              'Our data repository service ensures smooth reconciliation between counterparties, with effortless rewinds to quickly resolve issues and maintain data integrity.',
            image: wiring,
          },
          {
            title: 'Data Analysis',
            description:
              'Tools for analysing historical data and customer behaviour to fine-tune performance and help decision making.',
            image: analysis,
          },
        ],
      },
    ],
  },
  {
    slug: 'growing-income',
    title: 'Growing Income',
    imagedisplay: Income,
    description: `A Growing Income product provides customers with a stable, protected income stream from their pension pot while keeping them invested in the markets and benefit from positive market movements.`,
    features: [
      {
        icon: FaShieldAlt,
        title: 'Protected Income',
        description:
          'Guaranteed minimum income, regardless of market conditions.',
      },
      {
        icon: FaChartLine,
        title: 'Market-Linked Growth',
        description:
          'The protected income increases as markets perform. Increased income is locked in until the product ends.',
      },
      {
        icon: FaLocationArrow,
        title: 'Flexibility',
        description:
          'Customers can adjust income to suit their needs —opt for steady payouts or modify drawdowns, the product offers flexibility to accommodate changing financial circumstances.',
      },
      {
        icon: FaLock,
        title: 'Longevity Protection',
        description:
          'By offering a sustainable income stream that can grow over time, the product addresses the challenge of outliving one’s retirement savings. Some growing income products have longevity features.',
      },
    ],
    videoUrl: 'https://www.youtube.com/embed/u2hfFHha7xg',
    roles: [
      {
        title: 'Algorithm Development',
        description: `At the core of the Growing Income Plan are a fully customisable algorithms, designed to reflect the exact financial product you want to offer. We help shape their design, then handle the programming, testing, and flawless execution—so you can focus on your business and your clients.`,
        subs: [
          {
            title: 'Individual Investment Decisions',
            description:
              'Algorithms operate at the individual account or policy level, treating profile choices, contributions, withdrawals, and redemptions independently. This approach ensures maximum flexibility for the product.',
            image: individual,
          },
          {
            title: 'Asset Allocation',
            description:
              'The algorithm optimises asset allocation to balance growth and protection, ensuring the portfolio can grow while maintaining the required minimum income.',
            image: infra,
          },
          {
            title: 'Income Calculation',
            description:
              'Income Calculation: The algorithm calculates each customer’s income, increasing it as their investment portfolios grow.',
            image: invest,
          },
        ],
      },
      {
        title: 'Integration with Counterparties',
        description: `Our solution provides a single, centralised connection to the market. If you need to add or switch providers, no additional deployment is required on your side. We ensure smooth operations through:`,
        subs: [
          {
            title: 'Seamless Connections',
            description:
              'Secure integration with hedge providers, asset managers, and market data sources.',
            image: connections,
          },
          {
            title: 'Automated Operations',
            description:
              'Automated processing and trade execution minimises errors and streamlines processes.',
            image: automated,
          },
        ],
      },
      {
        title: 'Data Management and Storage',
        description:
          'We make a multitude of data easy to access, manipulate, and analyse. Our solution ensures:',
        subs: [
          {
            title: 'Secure Data Storage',
            description:
              'Robust storage that safeguards sensitive financial data and meets all regulatory requirements.',
            image: secure,
          },
          {
            title: 'Reconciliations and Rewinds',
            description:
              'Our data repository service ensures smooth reconciliation between counterparties, with effortless rewinds to quickly resolve issues and maintain data integrity.',
            image: wiring,
          },
          {
            title: 'Data Analysis',
            description:
              'Tools for analysing historical data and customer behaviour to fine-tune performance and help decision making.',
            image: invest,
          },
        ],
      },
    ],
  },
  {
    slug: 'smoothed-fund',
    title: 'Smoothed Fund',
    imagedisplay: Fund,
    description: `
    The Smoothed Fund is designed for individuals approaching or in retirement who want to reduce exposure to market volatility. It provides a balanced approach, offering stable returns even during market fluctuations.
    `,
    features: [
      {
        icon: FaShieldAlt,
        title: 'Volatility Smoothing',
        description:
          'Gradually absorbs the impact of market swings, providing a steadier investment experience and greater peace of mind.',
      },
      {
        icon: FaChartLine,
        title: 'Expected Return',
        description:
          'Returns can follow either a periodically reviewed Expected Growth rate or a Moving Average approach.',
      },
      {
        icon: FaLocationArrow,
        title: 'Diversification',
        description:
          'Investments can include a diversified mix of unit-linked assets, an insurer’s general account, or a with-profits fund.',
      },
    ],
    roles: [
      {
        title: 'Algorithm Design and Management',
        description:
          'A Smoothed Fund Product relies on a smoothing algorithm to maintain stability as well as on a set of business rules.',
        subs: [
          {
            title: 'Smoothing Mechanism',
            description:
              'The algorithm monitors the value of the underlying assets and adjusts the smoothed price to stay within a set range.',
            image: invest,
          },
          {
            title: 'Anti-arbitrage Monitoring',
            description:
              'The algorithm enforces agreed business rules to prevent any potential arbitrage behaviours.',
            image: monitor,
          },
        ],
      },
      {
        title: 'Integration with Counterparties',
        description:
          'Connecting the insurer who runs the smoothed fund to distribution platforms and other partners is essential. Unlike regular funds, smoothed funds need customised business rules. Quantessence simplifies this process with flexible integrations and seamless implementation.',
        subs: [
          {
            title: 'Seamless Connections',
            description:
              'Secure integration with platforms, asset managers, and market data sources.',
            image: connections,
          },
          {
            title: 'Operations Automation',
            description:
              'Automated processing of smoothing and business rules, as well as straight through trade execution minimises the risk of errors.',
            image: automated,
          },
        ],
      },
      {
        title: 'Data Management and Storage',
        description:
          'We make a multitude of data easy to access, manipulate, and analyse. Our solution ensures:',
        subs: [
          {
            title: 'Secure Data Storage',
            description:
              'Robust storage that safeguards sensitive financial data and meets all regulatory requirements.',
            image: secure,
          },
          {
            title: ' Reconciliations and Rewinds',
            description:
              'Our data repository service ensures smooth reconciliation between counterparties, with effortless rewinds to quickly resolve issues and maintain data integrity.',
            image: wiring,
          },
          {
            title: 'Data Analysis',
            description:
              'Tools for analysing historical data and customer behaviour to fine-tune performance and help decision making.',
            image: analysis,
          },
        ],
      },
    ],
  },
  {
    slug: 'lifecycle-pension-plan',
    title: 'Lifecycle Pension Plan',
    imagedisplay: Pension,
    description: `
      A pension savings solution that aligns risk appetite and retirement timeline with a dynamic allocation and protection mechanism. Safe asset allocation adapts to market conditions, not just to age, keeping savers invested longer positive markets. At retirement, the product transitions into a growing income product, ensuring long-term customer retention.
    `,
    features: [
      {
        icon: FaChartLine,
        title: 'Tailored Profiles',
        description:
          'Investment strategies are designed to match varying levels of risk tolerance and financial objectives, with automatic adjustments based on age to maintain an appropriate risk level throughout the lifecycle.',
      },
      {
        icon: FaCog,
        title: 'Lifecycle Approach',
        description:
          'Unlike standard lifecycle products, allocation to safer assets occurs only when markets underperform, allowing for longer exposure to positive market conditions.',
      },
      {
        icon: FaShieldAlt,
        title: 'Protection Options',
        description:
          'While the default protection increases with age—typically reaching maximum NAV protection at the end—customers can override and adjust protection levels at any time.',
      },
    ],
    videoUrl: 'https://www.youtube.com/embed/u2hfFHha7xg',
    roles: [
      {
        title: 'Algorithm Development',
        description:
          'At the core of the Lifecycle Pension Plan are a fully customisable algorithms, designed to reflect the exact financial product you want to offer. We help shape their design, then handle the programming, testing, and flawless execution—so you can focus on your business and your clients.',
        subs: [
          {
            title: 'Individual Investment Decisions',
            description:
              'Algorithms operate at the individual account or policy level, based on customer age, profile choices, contributions, withdrawals, and redemptions. This approach ensures maximum flexibility for the product.',
            image: individual,
          },
          {
            title: 'Asset allocation',
            description:
              'The algorithm optimises asset allocation to balance growth and protection, ensuring the portfolio can grow while maintaining the required age-dependent protection.',
            image: infra,
          },
          {
            title: 'Future Income Calculation',
            description:
              'The algorithm calculates each customer’s expected income, allowing customers to plan for their retirement.',
            image: invest,
          },
        ],
      },
      {
        title: 'Integration with Counterparties',
        description:
          'Our solution provides a single, centralised connection to the market. If you need to add or switch providers, no additional deployment is required on your side. We ensure smooth operations through:',
        subs: [
          {
            title: 'Seamless Connections',
            description:
              'Secure integration with hedge providers, asset managers, and market data sources.',
            image: connections,
          },
          {
            title: 'Automated Operations',
            description:
              'Automated processing and trade execution minimises errors and streamlines processes.',
            image: automated,
          },
        ],
      },
      {
        title: 'Data Management and Storage',
        description:
          'We make a multitude of data easy to access, manipulate, and analyse. Our solution ensures:',
        subs: [
          {
            title: 'Secure Data Storage',
            description:
              'Robust storage that safeguards sensitive financial data and meets all regulatory requirements.',
            image: secure,
          },
          {
            title: 'Reconciliations and Rewinds',
            description:
              'Our data repository service ensures smooth reconciliation between counterparties, with effortless rewinds to quickly resolve issues and maintain data integrity.',
            image: wiring,
          },
          {
            title: 'Data Analysis',
            description:
              'Tools for analysing historical data and customer behaviour to fine-tune performance and help decision making.',
            image: analysis,
          },
        ],
      },
    ],
  },
  {
    slug: 'indexed-universal-life',
    title: 'Indexed Universal Life',
    imagedisplay: Universal,
    description: `An Indexed Universal Life policy combines life insurance coverage with growth potential linked to a market index, offering both protection and flexible cash value accumulation. Policyholders benefit from market gains up to a cap while enjoying downside protection, ensuring that their cash value never decreases due to market losses.`,
    features: [
      {
        icon: FaShieldAlt,
        title: 'Full Downside Protection',
        description:
          'Capital remains secure whether allocated to fixed income or equity index exposure. Equity-linked growth is capped at a performance target, ensuring gains without risking the initial value.',
      },
      {
        icon: FaChartLine,
        title: 'Adaptive Strategy',
        description:
          'Flexibility to switch between fixed income, equity index exposure, or a blend of both, allowing adjustment to changing market conditions.',
      },
      {
        icon: FaLocationArrow,
        title: 'Simplicity',
        description:
          'Designed for clarity, making it easy to track investment value and adjust strategies based on market performance.',
      },
    ],
    roles: [
      {
        title: 'Algorithm Design and Management',
        description:
          'Indexed Universal Life policies combine fixed income instruments and equity options, managed through an algorithm tailored to the customer’s preferences. We support product design, programming, testing, and ongoing management of the equity options, fixed income portion, and individual policies.',
        subs: [
          {
            title: 'Individual Investment Decisions',
            description: `The algorithms track customer choices and calculates the value of each policy and its underlying components.`,
            image: individual,
          },
          {
            title: 'Option Calculation',
            description:
              'The Euroclear Quantessence platform features built-in option calculators for automated policy management.',
            image: invest,
          },
          {
            title: 'Flexibility',
            description:
              'Continuous automated calculations allow for more flexible adjustments to investment decisions.',
            image: freedom,
          },
        ],
      },
      {
        title: 'Integration with Counterparties',
        description:
          'Our solution provides a single, centralised connection to the market. If you need to add or switch providers, no additional deployment is required on your side. We ensure smooth operations through:',
        subs: [
          {
            title: 'Seamless Connections',
            description:
              'Secure integration with hedge providers, asset managers, and market data sources.',
            image: connections,
          },
          {
            title: 'Automated Operations',
            description:
              'Automated processing and trade execution minimises errors and streamlines processes.',
            image: automated,
          },
        ],
      },
      {
        title: 'Data Management and Storage',
        description:
          'We make a multitude of data easy to access, manipulate, and analyse. Our solution ensures:',
        subs: [
          {
            title: ' Secure Data Storage',
            description:
              'Robust storage that safeguards sensitive financial data and meets all regulatory requirements.',
            image: secure,
          },
          {
            title: 'Reconciliations and Rewinds',
            description:
              'Our data repository service ensures smooth reconciliation between counterparties, with effortless rewinds to quickly resolve issues and maintain data integrity.',
            image: wiring,
          },
          {
            title: 'Data Analysis',
            description:
              'Tools for analysing historical data and customer behaviour to fine-tune performance and help decision making.',
            image: invest,
          },
        ],
      },
    ],
  },

  {
    slug: 'unit-linked-support',
    title: 'Unit Linked Support',
    imagedisplay: Portfolio,
    description: `
    Managing a unit linked business and adjusting asset allocations can expose companies to liquidity and market risks. The Euroclear Quantessence platform addresses this by operating at a highly granular level (policy or contribution), reducing risk exposure and speeding up operations. For some products, a Value at Risk approach can be used to manage risk within a specific budget, offering additional control and predictability. Moreover, by leveraging the Euroclear Quantessence platform, insurers can more easily embrace digital transformation. This allows them to launch a diverse range of innovative savings products, all supported by the platform—without the need for extra in-house development. 
    `,
    features: [],
    roles: [
      {
        title: 'Algorithm Design and Management',
        description:
          'We help design and execute algorithms to power every process—handling programming, testing, and seamless deployment so you can focus on your business.',
        subs: [
          {
            title: 'Automation',
            description:
              'Automated rebalancing and order management streamline operations, cutting down timings and minimising market and liquidity risks.',
            image: aichip,
          },
        ],
      },
      {
        title: 'Integration with Counterparties',
        description:
          'Our solution provides a single, centralised connection to the market. If you need to add or switch providers, no additional deployment is required on your side. We ensure smooth operations through:',
        subs: [
          {
            title: 'Seamless Connections',
            description:
              'Secure integration with hedge providers, asset managers, and market data sources.',
            image: connections,
          },
          {
            title: 'Automated Operations',
            description:
              'Automated processing and trade execution minimises errors and streamlines processes.',
            image: automated,
          },
        ],
      },
      {
        title: 'Data Management and Storage',
        description:
          'We make a multitude of data easy to access, manipulate, and analyse. Our solution ensures:',
        subs: [
          {
            title: 'Secure Data Storage',
            description:
              'Robust storage that safeguards sensitive financial data and meets all regulatory requirements.',
            image: secure,
          },
          {
            title: 'Reconciliations and Rewinds',
            description:
              'Our data repository service ensures smooth reconciliation between counterparties, with effortless rewinds to quickly resolve issues and maintain data integrity.',
            image: wiring,
          },
          {
            title: 'Data analysis',
            description:
              ' Tools for analysing historical data and customer behaviour to fine-tune performance and help decision making.',
            image: analysis,
          },
        ],
      },
    ],
  },
];

export default products;
