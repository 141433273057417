import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useScroll from '../hooks/useScroll';
import { motion } from 'framer-motion';

import NavItems from './NavItems';
import MobileNavItems from './MobileNavItems';
import Button from '../components/Button';
// import Languages from './Languages';

import QW from '../assets/Q Logo White.svg';
import QR from '../assets/Q Logo Red.svg';
import QuantessenceW from '../assets/Quantessence Logo White.svg';
import QuantessenceR from '../assets/Quantessence Logo Red.svg';

import { MdMenu } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';

const menuVariants = {
  hidden: {
    opacity: 0,
    x: '100%',
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHomepage = pathname === '/';
  const isScrolling = useScroll();
  const [menuOpen, setMenuOpen] = useState(false);

  const bgClass =
    isScrolling || !isHomepage
      ? 'bg-white text-primary-blue'
      : 'bg-transperent text-white';

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleContactClick = () => {
    navigate('/contact');
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleNavItemClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const appContent = document.querySelector('.app-content');

    if (appContent) {
      appContent.classList.toggle('blur', menuOpen);
    }

    return () => {
      if (appContent) {
        appContent.classList.remove('blur');
      }
    };
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [menuOpen]);

  return (
    <div style={{ marginTop: !isHomepage || isScrolling ? '100px' : '0' }}>
      <header className={`fixed top-0 w-full z-50 ${bgClass}`}>
        <nav className='px-12 py-6'>
          <div className='flex items-center justify-between mx-auto max-w-[1600px]'>
            <div className='flex justify-start items-center gap-6'>
              <div className='flex flex-rcol lg:flex-col items-center gap-6'>
                <img
                  className='h-12 cursor-pointer'
                  src={isHomepage && !isScrolling ? QW : QR}
                  alt='logo'
                  onClick={handleLogoClick}
                />
                {isHomepage && !isScrolling ? (
                  <img
                    className='hidden lg:flex h-4 w-48 cursor-pointer'
                    src={QuantessenceW}
                    alt='Quantessence'
                  />
                ) : (
                  <img
                    className='hidden lg:flex h-4 w-48 cursor-pointer'
                    src={QuantessenceR}
                    alt='Quantessence'
                  />
                )}
              </div>
            </div>
            <button className='lg:hidden' onClick={toggleMenu}>
              <div
                className={`transition-transform duration-300 ease-in-out transform ${
                  menuOpen ? 'rotate-90' : ''
                }`}
              >
                <MdMenu
                  size={48}
                  className={` ${menuOpen ? 'hidden' : 'block'}`}
                />
                <IoClose
                  size={48}
                  className={` ${menuOpen ? 'block' : 'hidden'}`}
                />
              </div>
            </button>
            <div className='hidden lg:flex lg:justify-between lg:items-center w-full lg:w-auto lg:order-1 gap-6'>
              <NavItems
                activePage={pathname}
                textClass={
                  isScrolling || !isHomepage
                    ? 'text-primary-blue'
                    : 'text-white'
                }
                onItemClick={handleNavItemClick}
              />
              <Button onClick={handleContactClick}>Contact us</Button>
              {/* <Languages /> */}
            </div>
          </div>
          {menuOpen && (
            <motion.div
              variants={menuVariants}
              initial='hidden'
              animate={menuOpen ? 'visible' : 'hidden'}
              className='fixed inset-0 right-24 bg-white flex flex-col py-6 lg:hidden md:w-1/2'
            >
              <MobileNavItems
                activePage={pathname}
                onItemClick={handleNavItemClick}
              />
            </motion.div>
          )}
        </nav>
      </header>
    </div>
  );
};

export default Header;
