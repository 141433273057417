import React, { useState, useRef } from 'react';
import Button from '../Button';
import ReCaptcha from '../Contact/Recaptcha';
import { createUrl } from '../../utils.js';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    message: '',
    token: '',
  });

  const [emailError, setEmailError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const recaptchaRef = useRef(null);

  const disallowedDomains = [
    'gmail.com', 'hotmail.com','hotmail.co.uk', 'yahoo.com', 'aol.com', 'icloud.com',
    'mail.com', 'outlook.com', 'zoho.com', 'gmx.com', 'inbox.com',
    'yandex.com', 'protonmail.com', 'tutanota.com', 'fastmail.com',
    'hushmail.com', 'sendinblue.com', 'mailinator.com', 'mail.ru',
    'rediffmail.com',
  ];

  const resetMessage = () => {
    setTimeout(() => {
      setIsSubmitted(false);
      setIsFailed(false);
    }, 15000);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handlePrivacyPolicyChange = (e) => {
    setPrivacyPolicyChecked(e.target.checked);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      return 'Please enter a valid email address.';
    }

    const domain = email.split('@')[1];
    if (disallowedDomains.includes(domain)) {
      return 'Please use a valid company email address.';
    }

    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailValidationError = validateEmail(formData.email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    setEmailError('');

    try {
      const endpoint = createUrl('/send-email');
      console.log('POST TO ' + endpoint);
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setIsSubmitted(true);
        setIsFailed(false);

        setFormData({
          email: '',
          firstName: '',
          lastName: '',
          message: '',
          token: '',
        });

        setPrivacyPolicyChecked(false);
        recaptchaRef.current.resetRecaptcha();
        resetMessage();
      } else {
        setIsSubmitted(false);
        setIsFailed(true);
        resetMessage();
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSubmitted(false);
      setIsFailed(true);
      resetMessage();
    }
  };

  const handleVerify = (newToken) => {
    setFormData((prevData) => ({ ...prevData, token: newToken }));
  };

  return (
    <div className='flex flex-col w-full lg:w-1/2 lg:order-2 order-1'>
      <form onSubmit={handleSubmit}>
        <div className='sm:grid-cols-2 grid grid-cols-1 gap-x-8 gap-y-8'>
          {['firstName', 'lastName'].map((field) => (
            <div key={field}>
              <label
                htmlFor={field}
                className='block text-lg lg:text-xl text-primary-blue'
              >
                {field === 'firstName' ? 'First Name' : 'Last Name'}
              </label>
              <input
                id={field}
                placeholder={field === 'firstName' ? 'First Name' : 'Last Name'}
                type='text'
                value={formData[field]}
                onChange={handleInputChange}
                className='block w-full rounded border-0 px-3.5 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-8'
              />
            </div>
          ))}
          <div className='sm:col-span-2'>
            <label
              htmlFor='email'
              className='block text-lg lg:text-xl text-primary-blue'
            >
              Email
            </label>
            <input
              id='email'
              placeholder='name@example.com'
              type='email'
              value={formData.email}
              onChange={handleInputChange}
              className={`block w-full rounded border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6 ${
                emailError ? 'border-red-500 ring-red-500' : ''
              }`}
            />
            {emailError && (
              <p className='text-primary-red text-sm'>{emailError}</p>
            )}
          </div>

          <div className='sm:col-span-2'>
            <label
              htmlFor='message'
              className='block text-lg lg:text-xl text-primary-blue'
            >
              Message
            </label>
            <textarea
              id='message'
              placeholder='Message'
              rows={4}
              value={formData.message}
              onChange={handleInputChange}
              className='block w-full rounded border-0 px-3.5 py-2 text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-blue sm:text-sm sm:leading-6'
            />
          </div>
          <div className='flex items-center sm:col-span-2 gap-2'>
            <input
              id='privacy-policy'
              type='checkbox'
              className='h-4 w-4 text-primary-red border border-gray-300 rounded'
              checked={privacyPolicyChecked}
              onChange={handlePrivacyPolicyChange}
              required
            />
            <label
              htmlFor='privacy-policy'
              className='block text-sm text-gray-500'
            >
              By selecting this, you agree to our{' '}
              <a
                href='/privacypolicy'
                className='font-semibold text-primary-red'
              >
                privacy&nbsp;policy
              </a>
              .
            </label>
          </div>
        </div>

        <ReCaptcha ref={recaptchaRef} onVerify={handleVerify} />

        <div className='flex justify-start items-center mt-12'>
          <Button className='w-48' type='submit'>
            Let's talk
          </Button>
        </div>
      </form>

      {isSubmitted && (
        <div className='text-green-500 mt-4'>
          Thank you for contacting us! Our team will be in touch with you
          shortly.
        </div>
      )}
      {isFailed && <div className='text-red-500 mt-4'>Please try again!</div>}
    </div>
  );
};

export default ContactForm;
